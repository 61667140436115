import * as React from "react"
import { graphql } from "gatsby"
import BlogView from "../../views/blog-view"

function Blog(props) {
  const { blog } = props.data
  return <BlogView blog={blog}/>
}

export default Blog

export const query = graphql`
  query($id: String!) {
    blog(id: { eq: $id }) {
      headline
      description
      details
      image
      date
      links {
        url
        title
      }
    }
  }
`