import React from 'react';
import { Link } from "gatsby";

function formatCrumb(crumb, index) {
  let displaySeparator = index !== 0;
  let displayLink = crumb.url && true;

  return (
    <React.Fragment key={'crumb-' + index}>
      {
        displaySeparator &&
        <div className='breadcrumb-banner-path-separator'>/</div>
      }
      {
        displayLink &&
        <div className='breadcrumb-banner-path-link'><Link to={crumb.url}>{crumb.title}</Link></div>
      }
      {
        !displayLink &&
        <div className='breadcrumb-banner-path-text'>{crumb.title}</div>
      }
    </React.Fragment>
  );
}

export default function BreadcrumbBanner({ title, crumbs }) {
  return (
    <div className='breadcrumb-banner'>
      <div className='breadcrumb-banner-container'>
        <div className='breadcrumb-banner-inner'>
          <div>
            <h3>{title}</h3>
          </div>
          <div className='breadcrumb-banner-path'>
            {crumbs && crumbs.map((crumb, index) => formatCrumb(crumb, index))}
          </div>
        </div>
      </div>
    </div>
  );
}