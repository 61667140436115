import React from "react";
import ReactMarkdown from "react-markdown";
import DefaultLayout from "../layouts/DefaultLayout";
import { Link } from "gatsby";

function BlogView({ blog }) {
  let details = blog.details || ''

  let paragraphs = details.split('\n')
  let processedDetails = '';
  paragraphs.forEach(paragraph => {
    processedDetails = processedDetails + '\n\n' + paragraph;
  })
  console.log(details.split('\n'))


  return (
    <div>
      <DefaultLayout>
        <div className={'row'}>
          <div className={'col-md'} style={{fontFamily: '"fira sans", sans-serif'}}>
            <h1 style={{paddingBottom: '20px'}}>{blog.description}</h1>
            <div>
              <ReactMarkdown>{processedDetails}</ReactMarkdown>
            </div>
            {
              blog.links &&
              <div style={{paddingTop: '20px', paddingBottom: '5px'}}>Σχετικοί Σύνδεσμοι:</div>
            }
            <ul>
              {
                blog.links &&
                blog.links.map(link => <li><Link to={link.url}>{link.title}</Link></li>)
              }
            </ul>
          </div>
        </div>
      </DefaultLayout>
    </div>
  )
}

export default BlogView
